//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-200:_1048,_5988,_3404,_3312,_7384,_4688,_2128,_9028;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-200')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-200', "_1048,_5988,_3404,_3312,_7384,_4688,_2128,_9028");
        }
      }catch (ex) {
        console.error(ex);
      }